<template>
  <c-btn 
    v-if="editable&&!disabled"
    label="상세업무" 
    icon="info_outline">
    <template v-slot:tooltip>
      <q-popup-proxy transition-show="scale" transition-hide="scale">
        <div class="row impr-relation-table-info-row">
          <div class="col-12">
            <div class="card bg-primary widget-chart text-white card-border impr-relation-table-info-header">
              <div class="impr-relation-table-info-title">
                <div class="icon-wrapper rounded-circle impr-relation-table-info-title-icon">
                  <div class="icon-wrapper-bg bg-white opacity-1"></div>
                  <i class="lnr-bubble text-white"></i>
                </div>
                <div class="impr-relation-table-info-title-detail">{{improveRelationTask.relationTableTitle}}</div>
              </div>
              <div v-if="isExistsTask" class="text-white opacity-8 impr-relation-table-info-navi">
                <span class="pl-1">{{improveRelationTask.relationTableNavi}}</span>
              </div>
            </div>
            <div v-if="isExistsTask" class="card bg-white widget-chart card-border">
              <div class="impr-relation-table-info-contents" v-html="$comm.convertEnter(improveRelationTask.relationTableContents)"></div>
            </div>
          </div>
        </div>
      </q-popup-proxy>
    </template>
  </c-btn>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'imprRelationTag',
  props: {
    taskParam: {
      type: Object,
      default() {
        return {
          sopImprovementId: null,
          ibmTaskTypeCd: null,
          ibmTaskUnderTypeCd: null,
          relationTableKey: null,
        }
      },
    },
    disabled: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  data() {
    return {
      improveRelationTask: {
        sopImprovementId: null,
        ibmTaskTypeCd: null,
        ibmTaskUnderTypeCd: null,
        relationTableKey: null,
        relationTableTitle: '연결된 업무가 없습니다..',
        relationTableNavi: '',
        relationTableContents: '',
      },
      editable: true,
      detailUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  watch: {
    'taskParam.sopImprovementId': {
      handler: function () {
        this.getDetail();
      },
      deep: true,
    },
  },
  computed: {
    isExistsTask() {
      return Boolean(this.improveRelationTask.sopImprovementId);
    }
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.sop.ibm.improve.relationTask.get.url
      // code setting
      // list setting
      this.getDetail();
    },
    getDetail() {
      if (this.taskParam.sopImprovementId) {
        this.$http.url = this.$format(this.detailUrl, this.taskParam.sopImprovementId);
        this.$http.type = 'GET';
        this.$http.param = {
          ibmTaskTypeCd: this.taskParam.ibmTaskTypeCd,
          ibmTaskUnderTypeCd: this.taskParam.ibmTaskUnderTypeCd,
        };
        this.$http.request((_result) => {
          this.$_.extend(this.improveRelationTask, _result.data);
        },);
      } else {
        // nothing...
      }
    },
  }
};
</script>
<style scoped>
.impr-relation-table-info-row {
  width: 800px;
}
.impr-relation-table-info-header {
  padding: 0 1rem 1rem 1rem;
}
.impr-relation-table-info-title {
  margin: 1rem auto;
}
.impr-relation-table-info-title-icon {
  margin-right: 10px;
  display:inline-flex;
  width: 40px;
  height: 40px;
}
.impr-relation-table-info-title-icon i {
  font-size: 1.5rem;
}
.impr-relation-table-info-title-detail {
  font-weight: bold;
  font-size: 1.5rem;
  display: inline;
  line-height: 1;
  text-align: center;
}
.impr-relation-table-info-contents {
  margin: -0.5rem 0 0;
  display: block;
  text-align: left;
}
.impr-relation-table-info-navi {
  text-align: left;
}
</style>